﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminRolleRediger = function ($app) { // <-- Constructor method
        var $me = this;
        var $form = $me.smartform();

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.submit(function () {
                var data = $form.Read();
                $me.LagreRolle(data).done(function (result) {
                    if (!result.Success && result.Message) {
                        $.fn.Alert(result.Message);
                        return;
                    }
                    $me.Tilbakemelding.show().fadeOut(1600, "linear");
                });
                return false;
            });

            $('#farge').change(function () {
                var farge = $(this).val();
                $('#fargeikon').attr("class", "glyphicon glyphicon-stop text-" + farge);
            });
        });

        this.LoadItem = function (id) {
            if (id > 0) {
                $me.Handlingstype.append($me.Title2.text());
                $me.Id.val(id);
                $me.HentRolle(id).done(function (result) {
                    if (!result.Success && result.Message) {
                        if (result.ResultCode == 2) {
                            $.fn.Alert(result.Message, function () {
                                location = "#/Admin/Rolle/Oversikt";
                            });
                        } else {
                            $.fn.Alert(result.Message);
                            return;
                        }
                    }
                    $form.Write(result.Rolle);
                    $("#farge").trigger("change");
                });
            }
            else {
                $me.Handlingstype.append($me.Title3.text());
                $form.Write({});
            }
        };

        this.HentRolle = function (id) {
            return $app.APIGet("Admin/Rolle/Rediger", "HentRolle", { id: id }, { background: true });
        };

        this.LagreRolle = function (data) {
            return $app.APIPost("Admin/Rolle/Rediger", "LagreRolle", data, { background: true });
        };

        return this;
    };
})(jQuery);