﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.InnboksOversikt = function ($app) { // <-- Constructor method
        var $me = this;

        var utvalg = 0;
        var sortering = 2;
        var reversert = false;
        var oppdeling = null;
        var sideno = 0;
        var antall = 60;
        var antallDefault = 60;
        var ajax = null;
        var søketekst = "";
        var hovedkontraktId = null;
        var idlookup = {};
        var debouncer = null;

        var filterAlle = 1;
        var currentPage = 0;
        var scrollPos = 0;

        var filtrerHovedkontraktKey = window.localStorage.getItem("username") + "filtrerHovedkontrakt";
        
        // Define the jquery class
        this.on("viewinit", function () {

            var filtrerHovedkontrakt = window.localStorage.getItem(filtrerHovedkontraktKey);
            if ($me.isNum(filtrerHovedkontrakt)) 
                hovedkontraktId = filtrerHovedkontrakt;
            
            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"Sortert stigende\" class=\"glyphicon glyphicon-triangle-top sortdown\"></span>");
                $(this).append("<span title=\"Sortert synkende\" class=\"glyphicon glyphicon-triangle-bottom sortup\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;
                    // Stigende på kolonner som ikke er dato:
                    if (nysortering != 2) {
                        reversert = true;
                        sortering = nysortering * -1;
                    }
                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = !reversert;
                }
                $me.find("[data-s]").attr("class", null);

                $(this).addClass("sort" + (reversert ? "rev" : ""));
                $me.LoadItem(utvalg + "+" + oppdeling + "+" + sortering + "+" + encodeURI($me.SearchText.val()) + "+" + hovedkontraktId + "+" + currentPage + "+" + scrollPos + "+" + $me.FiltrerAlle.val());
            });

            $me.SearchBox.on("submit", function () {
                $me.LoadItem(utvalg + "+" + oppdeling + "+" + sortering + "+" + encodeURI($me.SearchText.val()) + "+" + hovedkontraktId + "+" + currentPage + "+" + scrollPos + "+" + $me.FiltrerAlle.val());
                return false;
            });

            $me.FiltrerAlle.on("change", function () {
                $me.SearchBox.trigger("submit");
            });

            debouncer = $me.debounce($me.HandleScroll, 50);
            $me.TabellContainer.on("scroll", debouncer);

            $(window).on("filterHovedkontraktChanged.oversikt", $me.FilterHovedkontrakt);

            $(window).on("resize.innboksoversikt", $me.OppdaterStickyHeader);

            $me.TabellContainer.on("scroll", function () {
                var left = $(this).scrollLeft();
                $me.StickyHeaderContainer.find("table").css({ "margin-left" : -left });
            });
        });

        this.on("viewclosing", function () {
            if (debouncer != null) $me.TabellContainer.off("scroll", debouncer);
            $(window).off("filterHovedkontraktChanged.oversikt");
            $(window).off("resize.innboksoversikt");
        });

        this.FilterHovedkontrakt = function () {
            var filtrerHovedkontrakt = window.localStorage.getItem(filtrerHovedkontraktKey);

            if ($me.isNum(filtrerHovedkontrakt)) {
                hovedkontraktId = filtrerHovedkontrakt;
            }
            $me.SearchBox.trigger("submit");
        };

        this.LoadItem = function (angittutvalg) {
            if (!angittutvalg) angittutvalg = "0";
            var res = angittutvalg.split("+");
            utvalg = res[0];
            oppdeling = res.length > 1 ? res[1] : "G0";
            sortering = res.length > 2 ? res[2] : 2; // Default sortering 2 (Siste aktivitet dato)
            var tekst = res.length > 3 ? res[3] : "";
            var hovedkontrakt = res.length > 4 && res[4] != "" ? res[4] : null;
            currentPage = res.length > 5 ? res[5] : 0;
            scrollPos = res.length > 6 ? res[6] : 0;
            filterAlle = res.length > 7 ? res[7] : 1;

            if (sortering < 0) {
                reversert = true;
            }

            $me.find("[data-s=\"" + Math.abs(sortering) + "\"]").addClass("sort" + (reversert ? "rev" : ""));

            if (tekst && tekst.length > 0) {
                søketekst = decodeURI(tekst);
                $me.SearchText.val(søketekst);
            }
            else
                søketekst = $me.SearchText.val();

            if ($me.isNum(hovedkontrakt)) {
                hovedkontraktId = hovedkontrakt;
            }

            sideno = 0;
            $me.LoadMore.show();
            $me.Refresh();
            $me.Liste.empty();
            $me.OppdaterStickyHeader();
        };

        this.isNum = function (val) {
            if (typeof val === "undefined") return false;
            if (val === null) return false;
            var num = parseInt(val);
            return !isNaN(num);
        };

        this.Refresh = function () {

            var historystring = "#/Innboks/Oversikt+" + utvalg + "+" + oppdeling + "+" + sortering + "+" + encodeURI(søketekst) + "+" +
                (hovedkontraktId != null ? hovedkontraktId : "") + "+" + currentPage + "+" + scrollPos + "+" + (filterAlle != null ? filterAlle : "")

            $app.replaceHistory(historystring);

            if (sideno == 0 && currentPage > 0) {
                // lag en "lang" side med alle oppgaver, slik at alt kan lastes i ett og scrolles til
                antall = (antall + 1) * currentPage;
            }

            return ajax = $me.HentOversikt(utvalg, sideno, antall, sortering, reversert, søketekst, oppdeling, hovedkontraktId, filterAlle).done(function (result) {
                antall = antallDefault;
                sideno = currentPage;

                ajax = null;
                if (!result.Success) {
                    $.fn.Alert(result.Message);
                    return;
                }

                $me.SearchText.select().focus();

                // Vis filtreringsmeny i Alle oppføringer
                if (utvalg && (utvalg == 2 || utvalg == 4)) {
                    $me.FiltrerAlle.val(filterAlle);
                    $me.FiltrerAlle.show();
                    $me.FiltrerAlle.select2({ theme: "bootstrap", dropdownAutoWidth: true });
                } else {
                    if ($me.FiltrerAlle.data("select2")) $me.FiltrerAlle.select2("destroy");
                    $me.FiltrerAlle.hide();
                }

                idlookup = {};
                $me.Liste.empty();
                $me.Tittel.text(result.Tittel);
                $me.Beskrivelse.text(result.Beskrivelse);

                $app.UpdateTitle("BIM2Share - " + result.Tittel);

                $me.Driftsmeldinger.empty();
                $.each(result.Driftsmeldinger, function (index, item) {
                    var alert = $("<div>").addClass("alert alert-warning");
                    var button = $("<button>");
                    button.attr("type", "button");
                    button.addClass("close");
                    button.append("<span aria-hidden=\"true\">&times;</span>");
                    button.on("click", function () {
                        var btn = $(this);
                        $.fn.Confirm($me.Tekst_8.text(), function () {
                            btn.closest("div").remove();
                            $me.MerkDriftsmeldingSomLest(item.Id);
                        });
                    });

                    alert.append(button);
                    alert.append("<strong>Driftsmelding: </strong>");
                    alert.append(item.Melding);
                    $me.Driftsmeldinger.append(alert);
                });

                // Endrer innsendtdato til opprettetdato, da utkast ikke har noen innsendt dato.
                if (utvalg == 1) $me.find("[data-s=\"12\"]").text($me.Tekst_7.text());

                if (result.Liste.length == 0) {
                    if (utvalg != 2) {
                        $me.Liste.append("<tr><td colspan=\"13\">" + $me.Tekst_2.text() + " - <a href=\"#/Innboks/Oversikt+2" + "+0+" + sortering + "+" + encodeURI($me.SearchText.val()) + "\">" + $me.Tekst_6.text() + "</a></td></tr>");
                    }
                    else {
                        $me.Liste.append("<tr><td colspan=\"13\">" + $me.Tekst_3.text() + "</td></tr>");
                    }
                }

                $me.WriteRows(result);

                $me.TabellContainer.scrollTop(scrollPos);
            });
        };

        this.LoadNextPage = function (søketekst) {
            return ajax = $me.HentOversikt(utvalg, sideno, antall, sortering, reversert, søketekst, oppdeling, hovedkontraktId, filterAlle).done(function (result) {
                ajax = null;

                if (!$me.HasDuplicates(result.Liste)) {
                    $me.WriteRows(result);
                } else {
                    $me.ReloadWholeList();
                }
            });
        };

        this.HasDuplicates = function (rows) {
            if (!rows || !rows.length) return false;

            for (var i = 0; i < rows.length; i++) {
                if (idlookup[rows[i].Id]) return true;
            }
            return false;
        }

        this.ReloadWholeList = function () {
            console.log("Duplikate rader oppdaget, oppdaterer hele listen.");
            ajax = $me.HentOversikt(utvalg, 0, (sideno + 1) * antall, sortering, reversert, søketekst, oppdeling, hovedkontraktId, filterAlle).done(function (result) {
                ajax = null;

                idlookup = {};
                $me.Liste.empty();
                $me.WriteRows(result);
            });
        }

        this.WriteRows = function (result) {
            $.each(result.Liste, function () {
                idlookup[this.Id] = true;
                var $tr = $("<tr tabindex=\"0\" id=\"" + this.Id + "\" />");
                $tr.data("id", this.Id);
                var id = this.Oppføringsnr;
                if (id == null) id = "ny";
                var farge = "muted";
                if (this.Farge != null) farge = this.Farge;
                if (this.FristUtgått) $tr.addClass("text-danger");

                $tr.append("<td class=\"nobr\"><span class=\"openico glyphicon glyphicon-envelope text-" + farge + "\"></span> " + id + "</td>");
                $tr.append("<td>" + this.Tittel + "</td>");
                $tr.append("<td>" + formatDateTime(this.SisteAktivitet) + "</td>");
                $tr.append("<td>" + this.Oppføringstype + "</td>");
                if (result.Leder) $tr.append("<td data-s=\"4\">" + this.Prioritet + "</td>");
                $tr.append("<td>" + this.Hovedprosjekt + "</td>");
                $tr.append("<td>" + this.Hovedkontrakt + "</td>");
                $tr.append("<td>" + this.Kontraktsnr + " - " + this.Kontraktsnavn + "</td>");
                $tr.append("<td>" + this.LiggerHos + "</td>");
                $tr.append("<td>" + formatDate(this.InnsendtDato) + "</td>");

                var relaterte = "";
                $.each(this.Relaterte, function () {
                    if (relaterte.length)
                        relaterte += " ";

                    relaterte += "<a href=\"#/O+" + this.Id + "\">" + this.Oppføringsnr + "</a>";
                });
                $tr.append("<td>" + relaterte + "</td>");

                var status = "";
                if (utvalg == 0) {
                    if (result.VisNavnInnboksstatus) 
                        status = ((this.Lest == null) ? $me.Tekst_4.text() : $me.Tekst_5.text() + " " + formatDateTime(this.Lest) + (this.LestAv == null ? "" : " " + $me.Tekst_10.text() + " " + this.LestAv));
                    else 
                        status = ((this.Lest == null) ? $me.Tekst_11.text() : $me.Tekst_12.text() + " " + formatDateTime(this.Lest));
                } else {
                    status = (this.Status == null ? "-" : this.Status) + (this.Signatur != null ? ", " + this.Signatur : "");
                }

                $tr.append("<td>" + status + "</td>"); // Utkast

                $tr.append("<td><span class=\"badge\" title=\"" + $me.Tekst_9.text() + "\">" + (this.AntallVedlegg > 0 ? this.AntallVedlegg : "") + "</span></td>");

                var icon = "zoom-in";
                if (utvalg == 1) icon = "pencil";
                if (utvalg == 2) icon = "zoom-in";
                $tr.append("<td class=\"nopadd\"><a href=\"#/O+" + this.Id + "\" class=\"btn btn-default\"><span class=\"glyphicon glyphicon-" + icon + "\"></span></a></td>");
                $me.Liste.append($tr);

                if (this.Lest == null) $tr.css("font-weight", "bold");

            });

            if (result.Leder)
                $me.find("[data-s=\"4\"]").show();

            if (result.Liste.length < antall) {
                $me.LoadMore.hide();
            }

            $me.Liste.find("tr").on("dblclick", function () {
                var id = $(this).data("id");
                location = "#/O+" + id;
            });
            $me.Liste.find(".openico").on("click", function () {
                var id = $(this).closest("tr").data("id");
                location = "#/O+" + id;
            });

            $me.HandleScroll();
            $me.OppdaterStickyHeader();
        };

        this.OppdaterStickyHeader = function () {
            $me.TabellHeader.find("th").each(function (index, item) {
                var width = $(item).outerWidth();
                $me.StickyHeader.find("th:nth-child(" + (index + 1) + ")").css({
                    "width": width,
                    "min-width": width,
                    "max-width": width,
                });
            });
            
            var scrollbarWidth = $me.TabellContainer.prop("offsetWidth") - $me.TabellContainer.prop("clientWidth");
            var width = $me.TabellContainer.width() - scrollbarWidth;
            $me.StickyHeaderContainer.width(width);
        };

        this.HandleScroll = function () {
            var historystring = "#/Innboks/Oversikt+" + utvalg + "+" + oppdeling + "+" + sortering + "+" + encodeURI(søketekst) +
                "+" + (hovedkontraktId != null ? hovedkontraktId : "") + "+" + sideno + "+" + $me.TabellContainer.scrollTop() + "+" + (filterAlle != null ? filterAlle : "");

            $app.replaceHistory(historystring);

            if (ajax != null) return; // Aldready loading

            if ($me.isScrolledIntoView($me.LoadMore)) {
                sideno++;
                $me.LoadNextPage(søketekst);
            }
        };

        this.isScrolledIntoView = function (elem) {
            var docViewTop = $me.TabellContainer.scrollTop();
            var docViewBottom = docViewTop + $me.TabellContainer.height();
            var $e = $(elem);
            if (!$e.is(":visible")) return false;
            var elemTop = $e.offset().top - $e.parent().offset().top;

            return elemTop < docViewBottom;
        };

        this.debounce = function (fn, delay) {
            var timer = null;
            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    fn.apply(context, args);
                }, delay);
            };
        };

        this.HentOversikt = function (modus, side, sideantall, sortering, reversert, søketekst, oppdeling, hovedkontraktId, filterAlle) {
            if (!søketekst)
                søketekst = "";
            if (hovedkontraktId == "")
                hovedkontraktId = null;

            var prosessflytGruppeId = oppdeling != null && oppdeling.toLowerCase().startsWith("g") && oppdeling.length > 1 ? oppdeling.substr(1) : null;
            var prosessflytId = oppdeling != null && oppdeling.toLowerCase().startsWith("p") && oppdeling.length > 1 ? oppdeling.substr(1) : null;

            return $app.APIGet("Innboks/Oversikt", "HentOversikt", { modus: modus, side: side, sideantall: sideantall, sortering: sortering, reversert: reversert, søketekst: søketekst, prosessflytGruppeId: prosessflytGruppeId, prosessflytId: prosessflytId, hovedkontraktId: hovedkontraktId, filterAlle: filterAlle });
        };

        this.HentHovedkontrakter = function () {
            return $app.APIGet("Innboks/Oversikt", "HentHovedkontrakter");
        };

        this.MerkDriftsmeldingSomLest = function (id) {
            return $app.APIGet("Innboks/Oversikt", "MerkDriftsmeldingSomLest", { id: id }, { background: true });
        };

        return this;
    };


})(jQuery);