﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminProsjektKontrakter = function ($app) { // <-- Constructor method
        var $me = this;
        var $form;

        var roller = null;
        var brukerkontoer = null;
        var brukergrupper = null;
        var editid = null;
        var kontraktsnr = null;
        var kontraktstittel = null;
        var påkrevdeRoller = null;

        // Define the jquery class
        this.on("dialoginit", function () {
            $form = $me.Kontraktform.smartform();

            $form.on("attach", function (e, $el, $form) {
                $el.find("input[name=Oppføringer]").on("change", function () {
                    if ($(this).val() != "0") {
                        var btn = $el.closest("tr").find(".deletebtn");
                        btn.attr("disabled", true);
                        btn.attr("title", $me.Text1.text());
                        btn.off("click");
                    }
                });

                $el.find("input[name=Meldinger]").on("change", function () {
                    if ($(this).val() != "0") {
                        var btn = $el.closest("tr").find(".deletebtn");
                        btn.attr("disabled", true);
                        btn.attr("title", $me.Text1.text());
                        btn.off("click");
                    }
                });
                //$el.find("input[name=Utløpsdato]").on("change", function () {
                //    var v = $(this).val();
                //    var $tr = $(this).closest("tr");

                //    if (v) {
                //        var today = new Date();
                //        var utløpsdato = new Date(v);

                //        if (utløpsdato <= today) {
                //            $tr.addClass("text-danger");
                //            if (!$me.visUtløpte.prop("checked")) $tr.hide();
                //        }
                //        else {
                //            $tr.removeClass("text-danger");
                //        }
                //    }
                //});
                $el.find("select[name=BrukerId]").each(function () {
                    var $sel = $(this);
                    var $kontogroup = $("<optgroup>");
                    $kontogroup.attr("label", "Brukere");
                    $sel.append($kontogroup);
                    $.each(brukerkontoer, function () {
                        $kontogroup.append(new Option("<span class=\"fa fa-user text-muted\"></span> " + this.Navn, this.Id, false, false));
                    });
                    var $gruppegroup = $("<optgroup>");
                    $gruppegroup.attr("label", "Grupper");
                    $sel.append($gruppegroup);
                    $.each(brukergrupper, function () {
                        $gruppegroup.append(new Option("<span class=\"fa fa-users text-muted\"></span> " + this.Navn, this.Id, false, false));
                    });

                    $sel.on("change", function () {
                        $me.SjekkManglendeRoller();
                    });
                });
                
                $el.find("select[name=RolleId]").each(function () {
                    var $sel = $(this);
                    $.each(roller, function () {
                        $sel.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });

                    $sel.on("change", function () {
                        $me.SjekkManglendeRoller();
                    });
                });

                $el.find("select[name=BrukerId]").select2({
                    theme: "bootstrap",
                    escapeMarkup: function (markup) {
                        return markup;
                    },
                    templateResult: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    templateSelection: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    placeholder: $me.Text6.text()
                });

                $el.find("select[name=RolleId]").select2({
                    theme: "bootstrap",
                    escapeMarkup: function (markup) {
                        return markup;
                    },
                    templateResult: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    templateSelection: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    placeholder: $me.Text7.text()
                });

                $el.find("[name=Kontraktsnr]").attr("data-placeholder", kontraktsnr);
                $el.find("[name=Tittel]").attr("data-placeholder", kontraktstittel);
                
                $el.find("[name=Utløpt]").on("change", function () {
                    if ($(this).val() == "true") {
                        $el.find("td").attr("readonly", true);
                        $el.find("[contenteditable=\"plaintext-only\"]").attr("contenteditable", false).attr("readonly", true);
                        $el.find("input").attr("disabled", true);
                        $el.find(".select2 ").attr("disabled", true);
                        $el.find(".deletebtn").hide();

                        var $tr = $(this).closest("tr");
                        $tr.addClass("text-danger");
                        if (!$me.visUtløpte.prop("checked")) $tr.hide();
                    }
                });

                $el.find(".deletebtn").on("click", function () {
                    $.fn.Confirm($me.Text8.text(), function () {
                        $el.closest("tr").remove();
                        setTimeout(function () {
                            $("body").addClass("modal-open");
                        }, 250);
                    }, function () {
                        setTimeout(function () {
                            $("body").addClass("modal-open");
                        }, 250);
                    });
                });

            });

            $form.on("submit", function () {
                var data = $form.Read();
                data.Id = editid;
                $me.Lagre(data).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, function () {
                            setTimeout(function () {
                                $("body").addClass("modal-open");
                            }, 250);
                        }, "h3");
                        return false;
                    }

                    if (result.AndreHovedkontrakterMedLikeKontraktsnummer) {
                        var melding = "Endringene er lagret. Legg merke til at denne hovedkontrakten inneholder kontraktsnummer som også finnes i følgende hovedkontrakter: ";
                        melding += "<ul>"
                        $.each(result.AndreHovedkontrakterMedLikeKontraktsnummer.AndreHovedkontrakter, function (index, item) {
                            melding += "<li>\"" + item.HovedkontraktNavn + "\" i hovedprosjekt " + "<a href=\"#Admin/Prosjekt/Rediger+" + item.HovedprosjektId + "\" target=\"_blank\">" + item.HovedprosjektNavn + "</a></li>"
                        });
                        melding += "</ul>";
                        
                        $.fn.Alert(melding, null, "h4");
                    }

                    $me.closeDialog();

                });
                return false;
            });

            $form.on("removed", function () {
                $me.SjekkManglendeRoller();
            });

            $me.visUtløpte.on("change", function () {
                if ($me.visUtløpte.prop("checked")) {
                    $me.find("tr.text-danger").show();
                } else {
                    $me.find("tr.text-danger").hide();
                }
            });
        });

        this.LoadItem = function (id) {
            if (id == "") {
                $.fn.Alert($me.Text5.text(), null, "h3");
                $me.closeDialog();
                return;
            }

            $me.Hent(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, function () {
                        setTimeout(function () {
                            $("body").addClass("modal-open");
                        }, 250);
                    }, "h3");
                    return;
                }
                $me.Hovedkontraktnavn.text(result.Hovedkontraktnavn);
                editid = id;
                brukerkontoer = result.Brukerkontoer;
                brukergrupper = result.Brukergrupper;
                roller = result.Roller;
                kontraktsnr = result.HovedkontraktKontraktsnr;
                kontraktstittel = result.HovedkontraktKontraktstittel;
                påkrevdeRoller = result.PåkrevdeRoller;
                $form.find("[data-array] > *").remove();
                $form.Write(result);
                $me.SjekkManglendeRoller();
            });
        };

        this.SjekkManglendeRoller = function () {
            var manglendeRollerId = [];
            var manglendeRoller = "";

            var valgteRoller = [];
            $me.find("select[name=RolleId]:enabled").each(function () {
                var bruker = $(this).closest("tr").find("[name=BrukerId]").val();
                var slettet = false;

                if (bruker.length > 1 && bruker.substr(0, 1) == "b") {
                    $.each(brukerkontoer, function (index, item) {
                        //console.log(item.Id + " - " + bruker + " - " + item.Slettet);
                        if (item.Id == bruker && item.Slettet)
                            slettet = true;
                    });
                }
                else if (bruker.length > 0 && bruker.substr(0, 1) == "g") {
                    $.each(brukergrupper, function (index, item) {
                        if (item.Id == bruker && item.Slettet)
                            slettet = true;
                    });
                }

                if (!slettet) {
                    var val = $(this).val();
                    if (val != null && val != "undefined" && val != "")
                        valgteRoller.push(val);
                }
            });

            $.each(påkrevdeRoller, function () {
                var ok = false;
                $.each($(this), function (index, item) {
                    if ($.inArray(item.Id.toString(), valgteRoller) !== -1)
                        ok = true;
                });
                if (!ok) {
                    $.each($(this), function (index, item) {
                        if ($.inArray(item.Id.toString(), manglendeRollerId) === -1) {
                            manglendeRollerId.push(item.Id.toString());
                            manglendeRoller += (manglendeRoller.length == 0 ? "" : ", ") + item.Navn;
                        }
                    });
                }
            });

            if (manglendeRoller.length > 0) {
                $me.ManglendeRollerPanel.show();
                $me.ManglendeRoller.text(manglendeRoller);
            } else {
                $me.ManglendeRollerPanel.hide();
            }
        };

        this.Hent = function (id) {
            return $app.APIGet("Admin/Prosjekt/Kontrakter", "Hent", { id: id });
        };

        this.Lagre = function (data) {
            return $app.APIPost("Admin/Prosjekt/Kontrakter", "Lagre", data);
        };

        return this;
    };
})(jQuery);